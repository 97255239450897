import { Option, StatusOption } from "@/core/types/misc/Option";

export const statusConstant: StatusOption[] = [
  { value: true, label: "Active" },
  { value: false, label: "Non Active" },
];

export const serviceConstant: Option[] = [
  { value: "SKCK", label: "SKCK" },
  { value: "MEDICAL", label: "Medical" },
  { value: "CPMI_PROCESS", label: "CPMI Process" },
];

export const categoryConstant: Option[] = [
  { value: "VA", label: "Virtual Account" },
  { value: "QRIS", label: "QRIS" },
  { value: "CASH", label: "Cash" },
  { value: "EWALLET", label: "E-Wallet" },
];
